<template>
	<v-app>
		<v-layout>
			<v-app-bar color="primary">
				<v-container>
					<v-layout class="align-center header-bar">
						<v-app-bar-title>KMCP Portal</v-app-bar-title>
						<span class="desktop-only">
							<v-btn
								v-if="loginToken?.user.type == 'ADMIN'"
								:to="{ name: 'AdminHome' }"
							>
								<v-icon icon="mdi-home" class="mr-1"></v-icon>
								Admin
							</v-btn>
							<v-btn v-else :to="{ name: 'Home' }">
								<v-icon icon="mdi-home" class="mr-1"></v-icon>
								Home
							</v-btn>
							<v-btn v-if="loginToken" :to="{ name: 'Profile' }" class="ml-2">
								<v-icon icon="mdi-account-circle" class="mr-1"></v-icon>
								{{ loginToken.user.name }}
							</v-btn>
						</span>
						<v-menu v-if="loginToken">
							<template v-slot:activator="{ props }">
								<v-btn
									color="light"
									v-bind="props"
									icon="mdi-menu"
									class="ml-2"
								></v-btn>
							</template>
							<v-list>
								<div class="mobile-only">
									<v-list-item
										v-if="loginToken?.user.type == 'ADMIN'"
										:to="{ name: 'AdminHome' }"
									>
										<v-icon icon="mdi-home" class="mr-1"></v-icon>
										Admin
									</v-list-item>
									<v-list-item v-else :to="{ name: 'Home' }">
										<v-icon icon="mdi-home" class="mr-1"></v-icon>
										Home
									</v-list-item>
									<v-list-item :to="{ name: 'Profile' }">
										<v-icon icon="mdi-account-circle" class="mr-1"></v-icon>
										{{ loginToken.user.name }}
									</v-list-item>
									<v-divider />
									<v-divider v-if="loginToken?.user.type == 'ADMIN'" />
								</div>
								<v-list-item :to="{ name: 'Logout' }">
									<v-icon icon="mdi-login-variant" class="mr-1"></v-icon>
									Log out
								</v-list-item>
							</v-list>
						</v-menu>
					</v-layout>
				</v-container>
			</v-app-bar>
			<v-main>
				<v-container>
					<div class="horizontal-space"></div>
					<router-view />
				</v-container>
			</v-main>
		</v-layout>
	</v-app>
</template>
<script setup>
import { useHead } from 'unhead'
useHead({
	title: 'KMCP Portal',
	link: {
		rel: 'apple-touch-icon',
		sizes: '180x180',
		href: '/apple-touch-icon.png',
	},
	link: {
		rel: 'icon',
		sizes: '32x32',
		type: 'image/png',
		href: '/favicon-32x32.png',
	},
	link: {
		rel: 'icon',
		sizes: '16x16',
		type: 'image/png',
		href: '/favicon-16x16.png',
	},
})
</script>

<script>
import { mapState } from 'vuex'

export default {
	name: 'App',
	computed: {
		...mapState(['loginToken']),
	},
}
</script>

<style lang="less">
body {
	--main-color: #0071ca;
	--secondary-color: #1f3b3e;
	--grey-color: #4f6567;
	--bright-color: #10ff02;
}
.page-title {
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	margin: 20px 0 5px;
}
.horizontal-spacer {
	height: 80px;
}
.desktop-only {
	@media (max-width: 500px) {
		display: none;
	}
}
.mobile-only {
	@media (min-width: 499px) {
		display: none;
	}
}

.vue-select-holder {
	position: relative;
}
.vue-select-holder label {
	position: absolute;
	top: 6px;
	left: 16px;
	z-index: 3;
	pointer-events: none;
	color: #6f6f6f;
	font-size: 12px;
}
.vue-select-holder label + div .vs__selected-options {
	padding-top: 20px;
}
.vue-select {
	// box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
		0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
		0px 1px 3px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12)) !important;
	border: none;
	flex-direction: column-reverse;
	border-radius: 4px;
	.vs__dropdown-toggle {
		box-shadow: none;
		border: none;
		padding: 6px;
	}
	.vs__selected {
		background: white;
		font-size: 16px;
		padding: 3px 8px;
		margin: 0;
		border: none;
	}
	&.up {
		.vs__dropdown-menu {
			font-size: 16px;
			top: auto;
			bottom: 100%;
		}
	}
	.vs__dropdown-option {
		padding: 10px 20px;
		&:before {
			font: normal normal normal 24px/1 'Material Design Icons';
			content: '\F0131';
			display: inline-block;
			margin-right: 8px;
			vertical-align: middle;
		}
	}
	.vs__dropdown-option--highlight {
		background: rgb(226, 226, 226);
		color: black;
	}
	.vs__dropdown-option--selected {
		background: rgb(226, 226, 226);
		color: black;
		&:before {
			content: '\F0132';
		}
	}
}
</style>
