export const validEmail = [
	value => !!value || 'Email is required',
	value =>
		/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
		'Must be a valid email',
]

// validateEmail(input) {
// 	return String(input)
// 		.toLowerCase()
// 		.match(
// 			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// 		)
// },

export const passwordLength8 = [
	value => !!value || 'Required field',
	value => value.length >= 8 || 'Minimum length is 8 characters',
]

export const requiredField = [value => !!value || 'Required']
