<template>
	<LoginHolder>
		<v-card-title>Forgotten password</v-card-title>
		<v-form
			v-model="isFormValid"
			@submit.prevent="forgot"
			class="forgot-password-form"
		>
			<v-text-field
				v-model="email"
				label="E-postadresse"
				variant="solo"
				prepend-inner-icon="mdi-account"
				:rules="validEmail"
				autofocus
			></v-text-field>
			<v-btn
				:disabled="!isFormValid"
				type="submit"
				color="primary"
				elevation="0"
			>
				Reset password
			</v-btn>
		</v-form>
	</LoginHolder>
	<div class="other-links text-center my-5">
		<router-link :to="{ name: 'Login' }" class="mr-6">
			Want to login instead?</router-link
		>
	</div>
</template>

<script>
import APIService from '@/services/APIService.js'
import { useToast } from 'vue-toastification'
import { mapState } from 'vuex'
import LoginHolder from '@/components/LoginHolder.vue'
import { validEmail } from '@/utils/rules.js'

const toast = useToast()

export default {
	name: 'ForgotPassword',
	components: {
		LoginHolder,
	},
	data: () => ({
		email: '',
		isFormValid: false,
		validEmail,
		isLoading: false,
	}),
	methods: {
		forgot() {
			this.isLoading = true
			APIService.forgotPassword(this.email)
				.then(response => {
					toast.success(response.data.message)
				})
				.catch(error => {
					toast.error(error.response.data.message)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
	computed: {
		...mapState(['loginToken']),
	},
}
</script>
