<template>
	<!-- <span></span> -->
	<v-icon class="deselect-close-icon" icon="mdi-close-circle-outline"></v-icon>
</template>
<style>
.deselect-close-icon {
	color: grey;
	font-size: 0.9em;
}
</style>
