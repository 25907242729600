<template>
	<span class="indicator-caret-icon"></span>
</template>
<style>
.indicator-caret-icon {
	font: normal normal normal 24px/1 'Material Design Icons';
}
.indicator-caret-icon::before {
	content: '\F035D';
	color: rgba(0, 0, 0, 0.55);
}
</style>
