import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import APIService from '@/services/APIService.js'
import { useToast } from 'vue-toastification'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPassword,
	},
	{
		path: '/new-password',
		name: 'SetNewPassword',
		component: () =>
			import(
				/* webpackChunkName: "SetNewPassword" */ '../views/SetNewPassword.vue'
			),
		props: true,
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () =>
			import(/* webpackChunkName: "SetNewPassword" */ '../views/Profile.vue'),
	},
	{
		path: '/onboarding-video',
		name: 'OnboardingVideo',
		component: () =>
			import(
				/* webpackChunkName: "SetNewPassword" */ '../views/OnboardingVideo.vue'
			),
	},
	{
		path: '/checklist/:id',
		name: 'Checklist',
		component: () =>
			import(/* webpackChunkName: "Checklist" */ '../views/Checklist.vue'),
		props: true,
	},
	{
		path: '/logout',
		name: 'Logout',
		component: {
			beforeRouteEnter(to, from, next) {
				APIService.logout().finally(() => {
					store.commit('setLoginToken', null)
					next({
						name: 'Login',
					})
					return
				})
			},
		},
	},
	{
		path: '/admin/overview',
		name: 'AdminHome',
		component: () =>
			import(/* webpackChunkName: "AdminHome" */ '../views/AdminHome.vue'),
		props: true,
	},
	{
		path: '/testpage',
		name: 'testpage',
		component: () =>
			import(/* webpackChunkName: "testpage" */ '../views/testpage.vue'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

const toast = useToast()

router.beforeEach((to, from, next) => {
	const adminRoutes = ['AdminHome', 'AdminChecklist']
	const permissibleRoutes = [
		'Login',
		'ForgotPassword',
		'SetNewPassword',
		'OnboardingVideo',
	]
	const loggedOutRoutes = ['Login', 'ForgotPassword']

	if (loggedOutRoutes.includes(to.name) && store.state.loginToken) {
		next({ name: 'Home' })
		return
	}
	if (permissibleRoutes.includes(to.name)) {
		next()
		return
	}
	if (!store.state.loginToken) {
		next({ name: 'Login' })
		return
	}
	if (store.state.loginToken.user.type == 'ADMIN' && to.name == 'Home') {
		next({ name: 'AdminHome' })
		return
	}
	if (
		adminRoutes.includes(to.name) &&
		store.state.loginToken.user.type != 'ADMIN'
	) {
		toast.error('Access denied')
		next({ name: 'Home' })
		return
	}
	next()
})

export default router
