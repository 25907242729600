import { createStore } from 'vuex'

export default createStore({
	state: {
		loginToken: localStorageParse('loginToken'),
		activeYear: new Date().getFullYear(),
	},
	getters: {},
	mutations: {
		setLoginToken(state, payload) {
			if (payload == null) {
				localStorage.removeItem('loginToken')
			} else {
				localStorage.setItem('loginToken', JSON.stringify(payload))
			}
			state.loginToken = payload
		},
		setActiveYear(state, payload) {
			state.activeYear = payload
		},
	},
})

function localStorageParse(item) {
	return localStorage.getItem(item)
		? JSON.parse(localStorage.getItem(item))
		: null
}
