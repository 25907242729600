<template>
	<v-container class="login-container">
		<v-row justify="center">
			<v-card class="login-card card-default">
				<div class="card-header">
					<v-img src="@/assets/kmcp-logo.svg" alt="KMCP logo" />
				</div>
				<div class="pa-10 card-body">
					<slot></slot>
				</div>
				<v-card-actions v-if="$slots.actions">
					<slot name="actions"></slot>
				</v-card-actions>
			</v-card>
		</v-row>
	</v-container>
</template>

<style lang="less">
.card-default {
	width: 400px;
	max-width: 100%;
	.card-header {
		background-color: var(--secondary-color);
		padding: 15px 48px;
	}
	.v-img {
		padding: 20px;
	}
	.v-card-title {
		padding: 0;
		margin-bottom: 20px;
	}
}
</style>
