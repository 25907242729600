import axios from 'axios'
import store from '@/store'

let BASE_URL = process.env.VUE_APP_API_BASE_URL

const Service = axios.create({
	baseURL: BASE_URL,
})

Service.interceptors.request.use(config => {
	config.headers['token'] = store.state.loginToken
		? store.state.loginToken.token
		: null
	return config
})

Service.interceptors.response.use(
	response => {
		return response
	},
	error => {
		console.log(error)
		if (error.response.status == 401) {
			console.log('401')
			store.commit('setLoginToken', null)
		}
		return Promise.reject(error)
	}
)

export default {
	login(email, password) {
		return Service.post('/login', null, {
			params: {
				email: email,
				password: password,
			},
		})
	},
	logout() {
		return Service.post('/logout', null, {})
	},
	forgotPassword(email) {
		return Service.post('/reset-password', null, {
			params: {
				email: email,
			},
		})
	},
	setNewPassword(password, token) {
		return Service.post('/reset-password-submit', null, {
			params: {
				password: password,
				token: token,
			},
		})
	},
	fetchAdminOverview() {
		return Service.get('/admin/overview')
	},
	createProperty(formData) {
		return Service.post('/admin/property', formData)
	},
	updateProperty(formData, id) {
		return Service.put('/admin/property/' + id, formData)
	},
	deleteProperty(id) {
		return Service.delete('/admin/property/' + id)
	},
	createUser(formData) {
		return Service.post('/admin/user', formData)
	},
	updateUser(formData, id) {
		return Service.put('/admin/user/' + id, formData)
	},
	deleteUser(id) {
		return Service.delete('/admin/user/' + id)
	},
	getProperties() {
		return Service.get('/properties')
	},
	getChecklist(checklistId) {
		return Service.get('/checklists/' + checklistId)
	},
	getLatestChecklist(propertyName) {
		return Service.get('/properties/' + propertyName)
	},
	getLatestChecklistAdmin(propertyName, userId) {
		return Service.get('/admin/properties/' + propertyName + '/' + userId)
	},
	updateChecklistAnswer(formData) {
		return Service.put('/checklist-answer', formData)
	},
	fileUpload(formData) {
		return Service.post('/file-upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	fileDescriptionUpdate(formData) {
		return Service.put('/file-description-update', formData)
	},
	fileDelete(id) {
		return Service.delete('/file-delete/' + id)
	},
	// Home
	getHome() {
		return Service.get('/my-page')
	},
	updatePassword(oldPassword, newPassword) {
		return Service.put('/my-page/password', null, {
			params: {
				oldPassword,
				newPassword,
			},
		})
	},
}
