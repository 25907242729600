<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import APIService from '@/services/APIService.js'

const user = ref(null)
const activeYear = ref(null)
const properties = ref([])

onMounted(() => {
	APIService.getHome().then(response => {
		if (response.status === 200) {
			user.value = response.data.data.user
			properties.value = response.data.data.properties
		}
	})
})

const selectableYears = computed(() => {
	if (!properties.value || !properties.value.length) return []
	return properties.value
		.map(x => x.checklists.map(y => y.year))
		.flat()
		.filter((value, index, self) => self.indexOf(value) === index)
		.sort((a, b) => b - a)
})
watch(selectableYears, () => {
	if (!selectableYears.value || !selectableYears.value.length) return
	activeYear.value = selectableYears.value[0]
})

function statusInfo(property) {
	if (!property.checklists.length) {
		return {
			color: 'text-grey',
			icon: 'mdi-clock-outline',
			text: 'You will be notified when a checklist is available.',
			clickable: false,
		}
	}
	const currentYearChecklist = property.checklists.find(
		x => x.year == activeYear.value
	)
	if (currentYearChecklist.status == 'COMPLETE') {
		return {
			...currentYearChecklist,
			color: 'text-success',
			icon: 'mdi-check-circle-outline',
			text: 'Complete',
			clickable: true,
		}
	}
	return {
		...currentYearChecklist,
		color: 'text-black',
		icon: 'mdi-arrow-right',
		text: 'Deadline: ' + currentYearChecklist.deadlineAt.substr(0, 10),
		clickable: true,
	}
}
</script>

<template>
	<div>
		<p v-if="user?.name" class="page-title">Welcome</p>
		<h1 v-if="user?.name" class="mb-10">{{ user.name }}</h1>

		<div class="properties-container">
			<v-card>
				<v-card-title>Your properties</v-card-title>
				<v-row>
					<v-col cols="12" md="4">
						<v-select
							v-if="selectableYears"
							:items="selectableYears"
							v-model="activeYear"
							:hide-details="true"
							variant="solo"
							prepend-inner-icon="mdi-calendar-month"
							class="ma-3"
						></v-select>
					</v-col>
				</v-row>
				<v-list>
					<v-list-item
						v-for="property in properties"
						:key="property.propertyId"
						:to="
							statusInfo(property).clickable
								? {
										name: 'Checklist',
										params: { id: statusInfo(property).id },
								  }
								: ''
						"
						:class="statusInfo(property).color"
					>
						<v-icon icon="mdi-home" class="mr-2"></v-icon>
						{{ property.propertyName }}
						<v-icon
							v-if="statusInfo(property).icon"
							:icon="statusInfo(property).icon"
							class="ml-2"
						></v-icon>
						<span v-if="statusInfo(property).text" class="ml-2">{{
							statusInfo(property).text
						}}</span>
					</v-list-item>
					<v-list-item v-if="properties && !properties.length" class="text-grey"
						>No properties</v-list-item
					>
				</v-list>
			</v-card>
		</div>
	</div>
</template>
