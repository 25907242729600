import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { createHead } from 'unhead'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import OpenIndicator from '@/components/OpenIndicator.vue'
import Deselect from '@/components/Deselect.vue'

loadFonts()

const head = createHead()

const toastOptions = {
	position: 'bottom-right',
	timeout: 5000,
	closeOnClick: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: false,
	closeButton: 'button',
	icon: true,
	rtl: false,
}

vSelect.props.components.default = () => ({
	Deselect,
	OpenIndicator,
})

createApp(App)
	.use(router)
	.use(store)
	.use(vuetify)
	.use(VueAxios, axios)
	.use(Toast, toastOptions)
	.component('vue-select', vSelect)
	.mount('#app')
