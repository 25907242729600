<template>
	<LoginHolder>
		<v-form v-model="isValidForm" @submit.prevent="login" class="login-form">
			<v-card-title>Login</v-card-title>
			<v-text-field
				v-model="email"
				label="Email"
				variant="solo"
				prepend-inner-icon="mdi-account"
				hide-details="auto"
				required
				autocomplete="email"
				:rules="validEmail"
				clearable
				autofocus
				class="mb-6"
			></v-text-field>
			<v-text-field
				v-model="password"
				:type="showPassword ? 'text' : 'password'"
				label="Password"
				autocomplete="current-password"
				variant="solo"
				prepend-inner-icon="mdi-lock"
				required
				hide-details="auto"
				:append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
				@click:append-inner="showPassword = !showPassword"
				:rules="requiredField"
				clearable
				class="mb-6"
			></v-text-field>
			<v-btn
				:disabled="!isValidForm || isLoading"
				type="submit"
				color="primary"
				elevation="0"
				size="large"
				>Login</v-btn
			>
			<br />
		</v-form>
	</LoginHolder>
	<div class="other-links text-center my-5">
		<router-link :to="{ name: 'ForgotPassword' }"
			>Forgot your password?
		</router-link>
	</div>
</template>

<script>
import APIService from '@/services/APIService.js'
import { useToast } from 'vue-toastification'
import { mapState } from 'vuex'
import { validEmail, requiredField } from '@/utils/rules.js'
import LoginHolder from '@/components/LoginHolder.vue'

const toast = useToast()

export default {
	name: 'Login',
	components: {
		LoginHolder,
	},
	data: () => ({
		isValidForm: false,
		email: '',
		password: '',
		showPassword: false,
		validEmail: validEmail,
		requiredField: requiredField,
		isLoading: false,
	}),
	methods: {
		login() {
			this.isLoading = true
			APIService.login(this.email, this.password)
				.then(response => {
					toast.success(response.data.message)
					this.$store.commit('setLoginToken', response.data.data.accessToken)
					if (this.loginToken.user.type == 'ADMIN') {
						this.$router.push({ name: 'AdminHome' })
					} else {
						this.$router.push({ name: 'Home' })
					}
				})
				.catch(error => {
					console.error(error)
					toast.error(error.response.data.message)
				})
				.finally(() => (this.isLoading = false))
		},
	},
	computed: {
		...mapState(['loginToken']),
	},
}
</script>
